import {useRoute} from '@react-navigation/native';
import {Div, HeaderBack, TimeLine} from 'components';
import {Ecolors} from 'constant';
import React, {useEffect, useRef, useState} from 'react';
import {Linking} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useDispatch} from 'react-redux';
import {getInfo} from 'reducer/authen';
import {detectLinkData} from 'utils';
import {setStoreData} from 'utils/storage';
import OrderBuy from './OrderBuy';
import OrderSell from './OrderSell';
import OrderTransfer from './OrderTransfer';

const switchTitleOrder = (t: 'BUY' | 'SELL' | 'TRANSFER' | 'TRANSFER_BUY') => {
  switch (t) {
    case 'BUY':
      return `createordermodal.datlenhmua`;
    case 'SELL':
      return `createordermodal.datlenhban`;
    case 'TRANSFER':
      return `createordermodal.datlenhchuyendoi`;
    case 'TRANSFER_BUY':
      return `createordermodal.datlenhchuyendoi`;
    default:
      return `createordermodal.datlenhban`;
  }
};

function CreateOrderModal() {
  const route = useRoute<any>();
  const dispatch = useDispatch();

  // const orderType: 'BUY' | 'SELL' | 'TRANSFER' = route.params?.orderType;
  const [orderType, setOrderType] = useState<'BUY' | 'SELL' | 'TRANSFER'>(
    'BUY',
  );
  const initData: any = route.params?.initData;
  const [stepTimeLine, setStepTimeLine] = useState<number>(1);
  const insests = useSafeAreaInsets();
  useEffect(() => {
    if (route.params?.orderType) {
      setOrderType(route.params?.orderType);
    } else {
      bindDataUrl();
    }

    return () => {};
  }, []);

  const bindDataUrl = async () => {
    Linking.getInitialURL()
      .then((r: any) => {
        const obj: any = detectLinkData(r);
        const {idNo, refCode, agentCode, secretKey, phone} = obj;
        if (idNo && phone && refCode && agentCode && secretKey) {
          dispatch(getInfo({}));
          // dispatch(changeStatusScreen('main'));
        }
        setStoreData('idNo', obj?.idNo || '');
        setStoreData('phone', obj?.phone || '');
        setStoreData('refCode', obj?.refCode || '');
        setStoreData('agentCode', obj?.agentCode || '');
        setStoreData('secretKey', obj?.secretKey || '');
      })
      .catch((err: any) => {});
  };
  const swithTypeOrder = (
    type: 'BUY' | 'SELL' | 'TRANSFER' | 'TRANSFER_BUY',
  ) => {
    switch (type) {
      case 'BUY':
        return (
          <OrderBuy
            initData={initData}
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
      case 'SELL':
        return (
          <OrderSell
            initData={initData}
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
      case 'TRANSFER':
        return (
          <OrderTransfer
            initData={initData}
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
      case 'TRANSFER_BUY':
        return (
          <OrderTransfer
            initData={initData}
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
      default:
        return (
          <OrderBuy
            stepTimeLine={stepTimeLine}
            setStepTimeLine={setStepTimeLine}
          />
        );
    }
  };

  return (
    <Div screen={true} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack type={2} title={switchTitleOrder(orderType)} />
      <TimeLine index={stepTimeLine} />
      <Div flex={1}>{swithTypeOrder(orderType)}</Div>
      <Div height={insests.bottom + 10} />
    </Div>
  );
}

export default React.memo(CreateOrderModal);
