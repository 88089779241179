export enum Icons {
  eyeClose = require('../assets/eyeClose/eyeClose.png'),
  eyeOpen = require('../assets/eyeOpen/eyeOpen.png'),
  back = require('../assets/back/back.png'),
  close = require('../assets/close/close.png'),
  warninglogin = require('../assets/warninglogin/warninglogin.png'),
  forward = require('../assets/forward/forward.png'),
  address = require('../assets/address/address.png'),
  condition = require('../assets/condition/condition.png'),
  login = require('../assets/login/login.png'),
  bank = require('../assets/bank/bank.png'),
  error = require('../assets/error/error.png'),
  phone = require('../assets/phone/phone.png'),
  check = require('../assets/check/check.png'),
  downloadqr = require('../assets/downloadqr/downloadqr.png'),
  uncheck = require('../assets/check/uncheck.png'),
  checklanguage = require('../assets/checklanguage/checklanguage.png'),
  checkconfirminfo = require('../assets/checkconfirminfo/checkconfirminfo.png'),
  edit = require('../assets/edit/edit.png'),
  finger = require('../assets/finger/finger.png'),
  faceid = require('../assets/faceid/faceid.png'),
  down = require('../assets/down/down.png'),
  uploadfile = require('../assets/uploadfile/uploadfile.png'),
  splash = require('../assets/splash/splash.png'),
  download = require('../assets/download/download.png'),
  bannerlogin = require('../assets/bannerlogin/bannerlogin.png'),
  fincorpbanner = require('../assets/fincorpbanner/fincorpbanner.png'),
  vietnam = require('../assets/vietnam/vietnam.png'),
  english = require('../assets/english/english.png'),
  delete = require('../assets/delete/delete.png'),
  swap = require('../assets/swap/swap.png'),
  search = require('../assets/search/search.png'),
  filter = require('../assets/filter/filter.png'),
  calendar = require('../assets/calendar/calendar.png'),
  notification = require('../assets/notification/notification.png'),
  buy = require('../assets/buy/buy.png'),
  sell = require('../assets/sell/sell.png'),
  transfer = require('../assets/transfer/transfer.png'),
  details = require('../assets/details/details.png'),
  priceup = require('../assets/priceup/priceup.png'),
  pricedown = require('../assets/pricedown/pricedown.png'),
  warningamount = require('../assets/warningamount/warningamount.png'),
  copy = require('../assets/copy/copy.png'),
  createordersuccess = require('../assets/createordersuccess/createordersuccess.png'),
  forwardregister = require('../assets/forwardregister/forwardregister.png'),
  before = require('../assets/ekyc/before.png'),
  after = require('../assets/ekyc/after.png'),
  cmnd = require('../assets/ekyc/cmnd.png'),
  erroricon = require('../assets/erroricon/erroricon.png'),
  digitalsignature = require('../assets/digitalsignature/digitalsignature.png'),
  fincorplable = require('../assets/fincorplable/fincorplable.png'),
  camera = require('../assets/camera/camera.png'),
  signature = require('../assets/signature/signature.png'),
  link = require('../assets/link/link.png'),
  uppecent = require('../assets/uppecent/uppecent.png'),
  downpecent = require('../assets/downpecent/downpecent.png'),
  listhori = require('../assets/listhori/listhori.png'),
  up = require('../assets/up/up.png'),
  listverti = require('../assets/listverti/listverti.png'),
  // icon footer app
  overview = require('../assets/footerapp/overview.png'),
  transaction = require('../assets/footerapp/transaction.png'),
  investment = require('../assets/footerapp/investment.png'),
  asset = require('../assets/footerapp/asset.png'),
  profile = require('../assets/footerapp/profile.png'),
  riskassessment = require('../assets/riskassessment/riskassessment.png'),
  iconapp = require('../assets/iconapp/iconapp.png'),
  //  list icon profile
  profileinfo = require('../assets/profiletab/profileinfo.png'),
  changepass = require('../assets/profiletab/changepass.png'),
  changephone = require('../assets/profiletab/changephone.png'),
  changeemail = require('../assets/profiletab/changeemail.png'),
  support = require('../assets/profiletab/support.png'),
  hotline = require('../assets/profiletab/hotline.png'),
  setting = require('../assets/profiletab/setting.png'),
  logout = require('../assets/profiletab/logout.png'),
  nonassets = require('../assets/nonassets/nonassets.png'),
  bgheader = require('../assets/bgheader/bgheader.png'),
}
