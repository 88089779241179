import {RootStackParamList} from 'utils';
/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import {LinkingOptions} from '@react-navigation/native';
import * as Linking from 'expo-linking';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      RegisterScreen: '/Register',
      AssetScreen: '/Asset',
      CreateOrderModal: '/CreateOrder',
      SetPasswordScreen: '/A',
      ReviewInfoModal: '/X',
      ControlEKYCScreen: '/B',
      EditBankInfoModal: '/C',
      OtpRequestModal: '/D',
      LoginScreen: '/L',
      DigitalSignatureScreen: '/Esign',
    },
  },
};

export default linking;
