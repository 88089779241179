import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import React , {useState} from "react";
import { AppContainer } from "./src/container";
export default function App() {
const [isLoadingComplete, setIsLoadingComplete] = useState(true);
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <>
        <SafeAreaProvider>
          <AppContainer/>
          <StatusBar hidden={true} />
        </SafeAreaProvider>
      </>
    );
  }
}
